.announcement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #F9F9F9;
  margin: 0 0 13px 0;
  height: 2.813rem;
}

.announcement_btn {
  width: 56px;
  border: 0px solid;
  border-radius: 4px;
  margin-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  margin-top: 1%;
}

.tab_text {
  margin-left: 20px;
  color: #000 !important;
  font-weight: 600;
}
.tab_text.active {
  margin-left: 20px;
  color: #009BDB !important;
  font-weight: 600;
}