@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  overflow-x: hidden;
}

.cust-btn {
  background: linear-gradient(90.94deg, #4BC9FD 1.85%, #009BDB 97.56%);
  color: #fff;
  border: 0;
}

.cust-header {
  text-align: center;
  margin-top: 50px;
  color: #009bdb;
  font-weight: 700 !important;
  font-size: 30px !important;
}

.partners_container {
  height: 14.625rem;
  background-color: #F9F9F9;
  padding-top: 15px;
  margin-top: 20px;
} 

@media (max-width: 400px) { 
  .partners_container {
    margin-top: 0;
  } 
}