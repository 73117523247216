.heading_container {
  margin-left: 184px;
  margin-top: 150px;
}

.heading_container h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 24px;
}

.heading_breadcrum {
  margin-top: 20px;
  font-size: 20px;
}

.heading_breadcrum a {
  text-decoration: none;
  color: #fff;
}
.heading_breadcrum span {
  margin: 0 10px;
}

.success-block {
  padding: 60px;
  background-color: #F9F9F9;
}
.success-block_image > img  {
  height: 360px;
}
.success-block_text-container {
  margin: 0;
  top: 25%;
  -ms-transform: translateY(25%);
  transform: translateY(25%);
  text-align: justify;
  color: #000;
  padding-left: 2%;
}
.success-block_text-container h2 {
  font-size: 18px;
  font-weight: 600;
}
.success-block_text-container p:first-child {
  font-size: 16px;
  font-weight: 400;
}
.success-block_text-container p:last-child {
  font-size: 14px;
  font-weight: 400;
}

.team_info-text {
  display: table;
}
.team_info-block {
  /* top: 5%;
  -ms-transform: translateY(5%);
  transform: translateY(5%); */
  display: table-cell;
  vertical-align: middle;
}
.team_info-right_padding {
  padding-left: 20px;
}
.team_info-block h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.team_info-block h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
}
.team_info-block p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: justify;
}

.betric_pledge {
  margin-top: 40px;
  margin-bottom: 70px;
}
.betric_pledge h2 {
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
}
.betric_pledge h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.betric_pledge p:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
.betric_pledge p:last-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
}

.company{
  background: #F9F9F9;
  padding: 10px;
  margin-top: 10px;
}
.company_info-block {
  margin: 15px;
}
.company_info-block .company_info-image {
  width: 90px;
  height: 90px;
  background: #e3e3e3;
  border-radius: 50%;
  margin: auto;
  text-align: center;
}
.company_info-block .company_info-image img {
  top: 50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.company_info-block h4 {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 45px;
}
.company_info-block p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 768px) {
  .heading_container {
    margin: auto;
    margin-top: 80px;
  }

  .success-block {
    padding: 20px 30px;
  }
  .success-block_image { 
    text-align: center;
  }
  .success-block_image > img {
    width: 350px;
    height: 250px;
  }

  .success-block_text-container {
    margin: 0;
    padding: 0 20px;
    top: 0;
    transform: translateY(5%);
    text-align: justify;
  }


  .team_info-block {
    top: 0%;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding: 20px;
  }

  .betric_pledge {
    padding: 20px;
    margin-bottom: 40px;
    text-align: justify;
  }
}

@media (max-width: 400px) { 
  .success-block {
    padding: 40px 0;
  }
  .success-block_image > img {
    width: 300px;
    height: 200px;
  }

  .success-block_text-container {
    margin: 0;
    padding: 0 20px;
    top: 0;
    transform: translateY(10%);
    text-align: justify;
  }

  .about_logo_container {
    padding-left: 180px;
    overflow-x: scroll;
  }
}