
.imageLogo {
    margin: 0 20px;
    height: 40px;
}

.imageLogoBig {
    margin: 0 20px;
    height: 30px;
}


@media (max-width: 768px) {
    .imageLogo {
        margin: 10px;
        height: 25px;
    }

    .imageLogoBig {
        margin: 10px;
        height: 15px;
    }
}

@media (max-width: 400px) {
    .imageLogo {
        height: 25px;
    }

    .imageLogoBig {
        height: 20px;
    }
}