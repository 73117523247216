.heroHeader {
  font-weight: 900;
  font-size: 42px;
  letter-spacing: 0.08em;
}

.maskOurPage {
  padding-top: 50px;
}

.maskOurPage p {
  width: 400px;
  margin: 0 auto;
}

.heroSection{
  background-color: #009BDB;
}

.heroSection .imageText > img {
  height: 50px;
  width: 150px;
}

.iPhoneConatiner {
  top: -55%;
  transform: translateY(-55%);
}

.iPhoneConatiner > img {
}

.iPhoneConatiner > img:first-child {
  width: 150px;
  margin-top: 80px;
}

.iPhoneConatiner > img:nth-child(2) {
  margin: 0 100px;
  width: 200px;
}

.iPhoneConatiner > img:last-child {
  width: 150px;
  margin-top: 80px;
}
.ourAppLogo {
  height: 5rem;
  margin-top: -150px;
}
.imageText {
  padding: 30px 0;
}
.verticallyCenterText {
  width: 350px;
  margin: 0;
  top: 100%;
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.verticallyCenterText h2 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}
.verticallyCenterText p {
  font-weight: 400;
  font-size: 15px;
  color: #808080;
  line-height: 28px;
}
.phoneImage {
  width: 250px;
}

.logoContainer {
  width: 14rem;
  padding: 12px 0;
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

@media (max-width: 1080px) {
  .logoContainer {
    width: 13rem;
    padding: 15px;
    background-color: #f9f9f9;
    border: 0;
    margin-top: 10px;
  }
  .image_text_container {
    margin-top: 100px;
  }
  .margin_left_negative {
    margin-left: -65px;
  }
}

@media (max-width: 768px) {
  .heroSection {
    height: 60vh !important;
  }
  .maskOurPage h2 {
    padding-top: 30px;
    font-size: 24px;
  }
  .maskOurPage p {
    padding: 5px 10px;
    font-size: 13px;
  }

  .iPhoneConatiner > img:first-child {
    width: 70px;
    margin-top: 20px;
  }
  .iPhoneConatiner > img:nth-child(2) {
    margin: 0 10px;
    width: 120px;
  }
  .iPhoneConatiner > img:last-child {
    width: 70px;
    margin-top: 20px;
  }

  .ourAppLogo {
    margin-top: -100px;
    height: 1.5rem;
  }

  .phoneImage {
    margin-top: 30px;
  }

  .imageText {
    padding: 10px 0;
  }

  .verticallyCenterText {
    margin: 30px auto;
    text-align: center;
    top: 0;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
  .verticallyCenterText p {
    font-weight: 400;
    font-size: 13px;
    padding: 0 5px;
  }
  .logoContainer {
    width: 12rem;
    padding: 15px;
    background-color: #f9f9f9;
    border: 0;
    margin-top: 10px;
  }
  .image_text_container {
    margin-top: 200px;
  }
}

@media (max-width: 400px) {
  
  .logoContainer {
    width: 10rem;
    padding: 10px 0;
    border: 0;
    margin-top: 10px;
  }
  .maskOurPage {
    padding-top: 30px;
  }
  .maskOurPage h2 {
    width: 350px;
    margin: auto;
  }
  .maskOurPage p {
    width: 300px;
  }
  .iPhoneConatiner {
    transform: translateY(-45%);
  }
}
@media (max-width: 350px) {
  .maskOurPage {
    padding-top: 30px;
  }
  .maskOurPage h2 {
    width: 330px;
    margin: auto;
  }
}
