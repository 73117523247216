.lineStyle {
  width: 40px;
  background: linear-gradient(90.94deg, #4bc9fd 1.85%, #009bdb 97.56%);
  height: 4px;
}
.startText {
  padding-top: 40px;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 0.2em;
}
.footerContainer {
  background-color: #000;
}

.descText {
  font-size: 14px;
  padding-right: 30px;
}

/* footer h2 {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.2em;
} */
footer h6 {
  font-weight: 500;
  font-size: 24px;
}
footer a {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
}

.layer {
  background: linear-gradient(91.29deg, #00316b 2.98%, #0c5ab6 96.7%);
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.layer .subscribeForm {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}
.layer .subscribeForm input,
.layer .subscribeForm button {
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  padding: 11px;
}

.layer .subscribeForm input {
  width: 300px;
}

.layer .subscribeForm button {
  margin-left: 10px;
}

.socialIcons {
  margin: 10px !important;
}
.termsCond {
  background-color: "rgba(0, 0, 0, 0.2)";
  font-size: 12px;
}

@media (max-width: 768px) {
  .descText {
    padding: 10px;
  }
  .layer {
    position: relative;
  }
  .layer .subscribeForm {
    margin-top: 8px;
    position: relative;
    z-index: 1;
  }
  .layer .subscribeForm input,
  .layer .subscribeForm button {
    border-radius: 4px;
    font-size: 12px;
    padding: 6px;
  }
  .layer .subscribeForm input {
    width: 180px;
  }
  .socialIconsContainer {
  }
}


@media (max-width: 400px) { 
  .startText {
    padding-top: 10px;
  }
}