.heading_text {
  text-align: left !important;
  margin-top: 50px;
  color: #009bdb;
  font-weight: 700 !important;
  font-size: 30px !important;
  padding-bottom: 10px;
}

.contact-details {
    margin-left: 50px;
    margin-top: 150px;
}

.contact_items {
    display: flex;
    width: 380px;
}

.contact_items h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
}

.contact_items p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #808080;
}

.contact_icons {
    padding-right: 20px;
    padding-top: 5px;
}
.contact_text h4 {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
}
.contact_text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}
.contact_text a {
    font-weight: 400;
    font-size: 18px;
    line-height: 10px;
    color: #009bdb;;
}

@media (max-width: 768px) {
    .contact-details {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
    }
}

@media (max-width: 400px) {
    .contact_logo_container {
      padding-left: 180px;
      overflow-x: scroll;
    }
}

.spinner {
    animation: spin infinite 5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}