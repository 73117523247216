.hero {
  margin-left: 184px;
  margin-top: 160px;
}

.hero h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 24px;
}

.hero .p1,
.hero .p2 {
  margin-top: 20px;
}

.hero .p1 {
  font-size: 20px;
}

.hero .p2 {
  font-size: 16px;
}

.hero .subscribe-form {
  margin-top: 20px;
  display: flex;
}

.hero .subscribe-form input,
.hero .subscribe-form button {
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  padding: 11px;
}

.hero .subscribe-form input {
  width: 300px;
}

.hero .subscribe-form button {
  margin-left: 10px;
}

/* Gamblers Section  */
.gamblers-section>div {
  width: 100%;
}

.gambler_text {
  text-align: center;
  color: #808080;
  width: 700px;
  margin: auto;
}

.guide_section {
  color: #808080;
  width: 700px;
  margin: auto;
}

/* .gamblers-section > div > p {
  text-align: center;
  width: 700px;
  margin: auto;
} */

.gamblers-section>div>p:first-child {
  margin-top: 50px;
}

.gamblers-section>div>p:nth-child(2) {
  font-weight: 300;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}

.iPhones>img:first-child {
  margin-right: -30px;
  margin-top: 50px;
}

.iPhones>img:nth-child(2) {
  position: relative;
  z-index: 1;
}

.iPhones>img:last-child {
  margin-left: -30px;
  margin-top: 50px;
}

.cyborg {
  width: 200px;
  padding: 10px;
  border: 0px solid;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  margin-bottom: 30px;
}

.gambler_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  width: 700px;
  margin: auto;
}

.drive-section {
  margin: 40px 20px;
  padding: 20px 100px;
}

.drive-section_text {
  position: relative;
  justify-content: "center";
  align-items: "center";
  width: 360px;
}

.drive-section_text h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.drive-section_text p {
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  margin-right: 30px;
}

.drive-section_heading {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.drive-section_image img {
  width: 360px;
}

.bulletin-section {
  margin: 6.25rem 0 !important;
}

.bulletin-section p {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
}

.listIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

.pros_cons {
  margin-bottom: 40px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .heroSection {
    height: 50vh !important;
    text-align: center;
  }

  .hero {
    margin: 5px auto;
    margin-top: 80px;
    justify-content: center;
  }

  .hero h2 {
    font-weight: 500;
    font-size: 26px;
  }

  .hero p {
    margin-top: 8px;
  }

  .hero .p1 {
    font-size: 15px;
  }

  .hero .p2 {
    font-size: 12px;
  }

  .hero .subscribe-form {
    margin-top: 10px;
  }

  .hero .subscribe-form input,
  .hero .subscribe-form button {
    border-radius: 4px;
    font-size: 12px;
    padding: 6px;
  }

  .hero .subscribe-form input {
    width: 180px;
  }

  .gamblers-section {
    margin-bottom: 6rem;
  }

  .gamblers-section>div>p {
    width: 380px;
  }

  .iPhones>img {
    width: 100px;
  }

  .iPhones>img:first-child {
    margin-right: -15px;
    margin-top: 50px;
  }

  .iPhones>img:nth-child(2) {
    position: relative;
    z-index: 1;
  }

  .iPhones>img:last-child {
    margin-left: -15px;
    margin-top: 50px;
  }

  .cyborg {
    margin: 20px 0;
  }

  .gambler_heading {
    width: 350px;
    font-size: 24px !important;
    text-align: center;
  }

  .drive-section {
    margin: 0;
    padding: 0;
    padding-top: 20px;
  }

  .drive-section_text {
    position: relative;
    width: "100vw";
  }

  .drive-section_text p {
    margin: 0px;
  }

  .drive-section_heading {
    margin: 0;
    padding: 20px 0 0 0;
    text-align: center;
    position: relative;
  }

  .drive-section_heading p {
    padding: 0 0 20px 0;
  }

  .drive-section_image img {
    width: "100vw";
    padding: 0 20px;
  }

  .bulletin-section {
    margin: 0.625rem !important;
  }

  .pros_cons {
    margin-top: 20px;
    margin-bottom: 20px !important;
    width: 300px;
  }
}

@media (max-width: 400px) {
  .hero .subscribe-form {
    justify-content: center;
  }

  .gambler-container {
    width: 0;
    margin: 0;
    padding: 0;
  }

  .gambler_heading {
    text-align: center !important;
  }

  .gamblers-section>div>p {
    width: 300px;
    text-align: justify;
  }

  .gamblers-section {
    margin-bottom: 12rem;
  }

  .home_logo_container {
    padding-left: 180px;
    overflow-x: scroll;
  }
}